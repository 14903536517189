const home: route = {
    title: 'My Account',
    path: '/',
    component: 'home-page',
    require_auth: true,
    load: async () => {
        await import('./pages/home');
    },
};

const not_found: route = {
    title: 'Not Found',
    path: '/404',
    component: 'not-found-page',
    load: async () => {
        await import('./pages/not-found');
    },
};

const no_auth: route = {
    title: 'Authentication Required',
    path: '/no-auth',
    component: 'no-auth-page',
    load: async () => {
        await import('./pages/no-auth');
    },
};

const patient_list_page: route = {
    title: 'My Patients',
    path: '/patients',
    component: 'patient-list-page',
    require_auth: true,
    load: async () => {
        await import('./pages/patient-list');
    },
};

export const routes: route_list = {
    default: home,
    not_found: not_found,
    no_auth: no_auth,
    layout: {
        '/': home,
        '/404': not_found,
        '/no-auth': no_auth,
        '/patients': patient_list_page,
    },
    $outlet: document.getElementById('outlet')!,
};
