import { onAuthStateChanged, signInWithRedirect, signOut } from 'firebase/auth';
import { routes } from './routes';
import { auth, provider } from './firebase-config';

const state = {
    // Whether the app is ready to be used, goes to true when authentication is ready.
    ready: false,
    // Whether the user is signed in.
    signed_in: false,
};

onAuthStateChanged(auth, (user) => {
    if (user) {
        console.log('User is signed in.');
    } else {
        console.log('User is signed out.');
    }
    state.signed_in = !!user;
    state.ready = true;
    update_route();
});

const update_route = () => {
    if (!state.ready) return;
    if (routes.$outlet) {
        const params = new URLSearchParams(window.location.search);
        const { $outlet } = routes;
        const path = window.location.pathname || '/';
        let route = routes.layout[path] || routes.not_found;
        console.log('Route:', route);
        if (route.require_auth && !state.signed_in) {
            route = routes.no_auth;
        }
        // Special cases for login and logout.
        if (path == '/login') {
            if (!state.signed_in) {
                signInWithRedirect(auth, provider);
                return;
            }
            redirect(params.get('redirect') || '/');
            return;
        }
        if (path == '/logout') {
            signOut(auth).then(() => {
                window.location.pathname = '/';
            });
            return;
        }
        route
            .load()
            .then(() => {
                const template = document.createElement(route.component);
                $outlet.replaceChildren(template);
                window.document.title = route.title;
            })
            .catch((err) => {
                console.error(err);
            });
    } else {
        console.error('No outlet found');
    }
};

export const redirect = (path: string, event?: Event) => {
    event?.preventDefault();
    window.history.pushState({}, '', path);
    update_route();
};

window.addEventListener('load', update_route);
window.addEventListener('popstate', update_route, false);
