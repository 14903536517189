import { LitElement, html, css } from 'lit';
import { property, customElement } from 'lit/decorators.js';
import { redirect } from '../router';

/**
 * A simple link component. Pass the link you want to go to as the 'data-link' attribute.
 * The element is slottable, so you can pass in any content you want.
 */
@customElement('nav-link')
export class nav_link extends LitElement {
    static styles = css`
        :host {
            display: block;
            padding-left: 5px;
            padding-right: 5px;
        }
    `;

    /**
     * The link to go to when the user clicks on the link.
     */
    @property({ type: String, reflect: true, attribute: 'data-link' })
    link: string = '';

    protected render() {
        return html`<a href="${this.link}" @click=${(e: Event) => redirect(this.link, e)}><slot></slot></a>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'nav-link': nav_link;
    }
}
