import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators/custom-element.js';
import './link';

@customElement('side-bar')
export class sidebar extends LitElement {
    static styles = css`
        :host {
            display: grid;
            background-color: #a6a6a6;
            min-width: 200px;
            height: 100%;
            padding: 10px;
            align-content: space-between;
        }
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }
        li {
            padding: 10px;
        }
    `;

    protected render() {
        return html` <ul>
                <li>
                    <nav-link data-link="/">My Account</nav-link>
                </li>
                <li>
                    <nav-link data-link="/patients">My Patients</nav-link>
                </li>
                <li>
                    <nav-link data-link="/logout">Sign Out</nav-link>
                </li>
            </ul>
            <p>(C) Copyright 2023 Ilya Strugatskiy</p>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'side-bar': sidebar;
    }
}
