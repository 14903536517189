import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore, doc, collection } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider, useDeviceLanguage } from 'firebase/auth';

const firebaseConfig = {
    apiKey: 'AIzaSyB59GePndGJqIn-SeFASXtSkYM3l9rL81Y',
    authDomain: 'bmi.mamklearn.com',
    projectId: 'bmi-ijp',
    storageBucket: 'bmi-ijp.appspot.com',
    messagingSenderId: '680549186976',
    appId: '1:680549186976:web:62622b4580904ac7aff8c5',
    measurementId: 'G-PP4P5Q4B8N',
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// For public use.
export const auth = getAuth();
export const provider = new GoogleAuthProvider();
export const db = getFirestore(app);

useDeviceLanguage(auth);
